import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { Select } from 'antd';
import clsx from 'clsx';

import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import classes from './SimpleSelect.module.scss';
import { useSelectPopupContainer } from './useSelectPopupContainer';
import TextField from '../TextField/TextField';
import { useMemo } from 'react';

export interface Option<TValue extends string | number = string> {
  value: TValue;
  label: string;
  disabled?: boolean;
}

export interface SimpleSelectProps<TValue extends string | number = string> {
  className?: string;
  value: TValue | null;
  options: Readonly<Option<TValue>[]>;
  onChange: (newValue: TValue | null) => void;
  onSelect?: () => void;
  noValueLabel?: string;
  id?: string;
  notEmpty?: boolean;
  dropdownMatchSelectWidth?: boolean;
  showSearch?: boolean;
  filterOption?: (input: string, option: any) => boolean;
  isLoading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export default function SimpleSelect<TValue extends string | number = string>({
  className,
  value,
  options,
  onChange,
  onSelect,
  noValueLabel = 'Not Selected',
  id,
  notEmpty,
  dropdownMatchSelectWidth = false,
  filterOption,
  isLoading,
  disabled,
  readOnly,
}: SimpleSelectProps<TValue>) {
  const { getPopupContainer } = useSelectPopupContainer();
  const selectedValue = useMemo(
    () => options.find((option) => option.value === value)?.label ?? noValueLabel,
    [options, value, noValueLabel]
  );
  return (
    <div className={clsx(classes.selectWrapper, className)}>
      {readOnly ? (
        <TextField value={selectedValue} id={id ?? ''} readOnly />
      ) : (
        <Select
          showSearch
          filterOption={filterOption}
          optionFilterProp="children"
          suffixIcon={
            isLoading ? (
              <FontAwesomeIcon title="loading" icon={faSpinner} className="animate-spin" />
            ) : (
              <FontAwesomeIcon icon={faSortDown} />
            )
          }
          onChange={onChange}
          onSelect={onSelect}
          value={value}
          className={classes.select}
          id={id}
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          getPopupContainer={getPopupContainer}
          disabled={disabled}
        >
          {!notEmpty && <Select.Option value={null}>{noValueLabel}</Select.Option>}
          {options.map(({ label, value, disabled }) => (
            <Select.Option key={value} value={value} label={label} disabled={disabled}>
              {label}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
}
